import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import AttributeToProduct from "./AttributeToProduct";

function AddAttributeValueToProductPopup({
  isOpen,
  handleClose,
  productId,
  categoryId,
}) {
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {"Add Attribute Value to Product"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <AttributeToProduct productId={productId} categoryId={categoryId} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default AddAttributeValueToProductPopup;
