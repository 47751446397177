import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import * as api from "../../../utils/api/apiClient";

const SignUpForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    password: "",
    reset_password_ui_url: api.siteBaseURL + "/auth/reset_password",
  });

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await api.signUp(formData);
      toast.success(res.data?.message);
      setIsLoading(false);
      setTimeout(() => {
        if (res) {
          navigate("/auth/login");
        }
      }, 3000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response?.data.message);
      }
    }
  };

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-[95%] sm:w-1/2 md:w-1/3">
        <div className="text-center pb-5">
          <div className="flex justify-center items-center pb-2">
            <img src="/logo.webp" alt="logo" className="w-16 h-16" />
          </div>
          <h3 className="text-2xl font-semibold">Create your account</h3>
          <p className="text-sm text-mediumgray">
            Already have an account?&nbsp;
            <Link
              to="/auth/login"
              className="text-base text-blue duration-150 hover:underline"
            >
              Login
            </Link>
          </p>
        </div>
        <form className="flex flex-col gap-5">
          <div>
            <TextField
              id="full_name"
              label="Full Name"
              autoComplete="off"
              type="text"
              value={formData.full_name}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="full_name"
              onChange={handelChange}
            />
          </div>
          <div>
            <TextField
              id="email"
              label="Email"
              autoComplete="off"
              type="email"
              value={formData.email}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="email"
              onChange={handelChange}
            />
          </div>
          <LoadingButton
            color="primary"
            disabled={
              formData.email?.length > 0 && formData.full_name?.length > 0
                ? false
                : true
            }
            onClick={handleForm}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>Submit</span>
          </LoadingButton>
        </form>
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default SignUpForm;
