import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductCreatingSteps from "../../../components/ProductCreatingSteps";
import ProductImages from "../../../components/ProductComponent/ProductImages";
import { Helmet } from "react-helmet";

function AddImages() {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Images | Inflection Admin</title>
      </Helmet>
      {/* {loading && <Loading />} */}
      <div className="pt-2 pr-2 pl-2 sm:pl-0 pb-5">
        <ProductCreatingSteps step={1} />
        <div className="w-full sm:w-8/12 mx-auto">
          <div className="flex flex-col gap-5">
            {/* Product Images */}
            <ProductImages
              productId={id}
              title={"Save & Next"}
              navigate={() => {
                navigate(`/product_tags_attributes/${id}`);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddImages;
