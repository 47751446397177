import React, { useEffect, useState } from 'react'
import * as api from '../../../utils/api/apiClient'
import { toast } from 'react-toastify'
import { Button, Chip, Input, Stack, TextField } from '@mui/material'
import Loading from '../../../components/Loading'
import { Helmet } from 'react-helmet'

function CreateCategory() {
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [categoryId, setCategoryId] = useState()
  const [categoryAttributes, setCategoryAttributes] = useState([])
  const [categoryFormData, setCategoryFormData] = useState({
    name: '',
    description: '',
    thumbnail: {},
  })
  const [attributeName, setAttributeName] = useState('')
  useEffect(() => {
    if (categoryId) {
      const getAttribute = async () => {
        setLoading(true)
        try {
          const res = await api.getAttribute(categoryId)
          if (res) {
            setCategoryAttributes(res.data)
            setLoading(false)
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      }
      getAttribute()
    }
  }, [refresh, categoryId])
  // TODO: Creating Attributes showing error (column created_at cannot be null)

  const createCategory = async (event) => {
    setLoading(true)
    event.preventDefault()
    const data = new FormData()
    data.append('name', categoryFormData.name)
    data.append('description', categoryFormData.description)
    data.append('category_image', categoryFormData.thumbnail)
    try {
      const res = await api.createCategory(data)
      if (res) {
        setCategoryId(res.data.category.id)
        setLoading(false)
        setCategoryFormData({
          name: '',
          description: '',
          thumbnail: {},
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const createCategoryAttribute = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      const res = await api.addAttribute({
        name: attributeName,
        category_id: +categoryId,
      })
      if (res) {
        setRefresh(!refresh)
        setAttributeName('')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const removeAttribute = async (id) => {
    setLoading(true)
    try {
      const res = await api.removeAttribute(id)
      if (res) {
        setRefresh(!refresh)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }

  const handleChange = (event) => {
    if (event.target.name === 'thumbnail') {
      setCategoryFormData({
        ...categoryFormData,
        [event.target.name]: event.target.files[0],
      })
    } else {
      setCategoryFormData({
        ...categoryFormData,
        [event.target.name]: event.target.value,
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Categories Create | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className='sm:flex justify-between gap-3 pt-2 pr-2 pl-2 lg:pl-0 pb-5'>
        <div className='sm:w-[70%]'>
          <div className='flex justify-between items-center gap-5 pb-1'>
            <h1 className='font-medium'>Create Category</h1>
            <Button
              variant='text'
              onClick={() => {
                setCategoryId('')
              }}
            >
              Create New One
            </Button>
          </div>
          <form action='#' className='flex flex-col gap-5 w-full'>
            <div>
              <TextField
                id='name'
                label='Name'
                variant='outlined'
                fullWidth={true}
                value={categoryFormData.name}
                size='small'
                color='info'
                required
                name='name'
                onChange={handleChange}
              />
            </div>
            <div>
              <TextField
                id='description'
                label='Description'
                variant='outlined'
                multiline
                fullWidth={true}
                value={categoryFormData.description}
                size='small'
                color='info'
                required
                name='description'
                onChange={handleChange}
              />
            </div>
            <div>
              <Input
                id='thumbnail'
                name='thumbnail'
                type='file'
                disableUnderline={true}
                fullWidth={true}
                className='w-full border border-mediumgray border-opacity-50 hover:border-black rounded-md outline-none cursor-pointer p-1'
                onChange={handleChange}
              ></Input>
            </div>
            <div>
              <Button
                disabled={
                  !categoryFormData.name.length > 0 ||
                  !categoryFormData.description.length > 0
                  //   TODO: Add validation for Image
                }
                variant='contained'
                disableElevation
                onClick={(event) => {
                  createCategory(event)
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
        <div className='sm:w-[30%]'>
          <div className='flex flex-col gap-2 pt-2'>
            <h6 className='font-medium'>Create Attributes</h6>
            <div>
              <TextField
                id='name'
                disabled={categoryId ? false : true}
                label='Name'
                value={attributeName}
                variant='outlined'
                fullWidth={true}
                size='small'
                color='info'
                required
                name='name'
                onChange={(e) => {
                  setAttributeName(e.target.value)
                }}
              />
            </div>
            <div className='text-center'>
              <Button
                disabled={categoryId && attributeName.length > 0 ? false : true}
                onClick={(event) => {
                  createCategoryAttribute(event)
                }}
                variant='contained'
                disableElevation
              >
                Submit
              </Button>
            </div>
          </div>
          <div>
            {categoryAttributes.length > 0 && (
              <div className='border border-mediumgray  border-opacity-50 rounded-md p-1'>
                <h1 className='font-medium pb-1'>Created Attribute</h1>
                <Stack
                  direction='row'
                  spacing={1}
                  className='flex flex-wrap gap-2'
                >
                  {categoryAttributes.map((attribute, index) => (
                    <Chip
                      key={index}
                      label={attribute.name}
                      onDelete={() => removeAttribute(attribute.id)}
                      variant='outlined'
                    />
                  ))}
                </Stack>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateCategory
