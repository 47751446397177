import * as api from "../api/apiClient";

export const getOrders = async (limit = 15, page = 1) => {
  try {
    const res = await api.getAllOrders(limit, page);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getReviews = async (limit = 10, page = 1) => {
  try {
    const res = await api.getAllReviews(limit, page);
    return res.data.reviews;
  } catch (error) {
    console.log(error);
  }
};
export const getUsers = async (limit = 10, page = 1) => {
  try {
    const res = await api.getAllUsers(limit, page);
    return res.data.users;
  } catch (error) {
    console.log(error);
  }
};
export const getProducts = async () => {
  // TODO: pagination adding pending...
  try {
    const res = await api.getAllProducts();
    return res.data.products;
  } catch (error) {
    console.log(error);
  }
};
