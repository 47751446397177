import React, { useEffect, useState } from "react";
import {
  createTag,
  deleteTagById,
  getAllTags,
} from "../../utils/api/apiClient";
import { toast } from "react-toastify";
import CreateTag from "../../components/ReUseFunctionComponent/CreateTag";
import { Button, Chip, Stack, TextField } from "@mui/material";
import Loading from "../../components/Loading";
import { Helmet } from "react-helmet";

function AllTags() {
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [editState, setEditState] = useState({
    name: "",
    id: "",
  });

  useEffect(() => {
    const getTags = async () => {
      setLoading(true);
      try {
        const res = await getAllTags();
        setTags(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getTags();
  }, [refresh]);

  const createOneTag = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await createTag({ name: editState.name });
      if (res) {
        setEditState({ ...editState, name: "" });
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
  const deleteTag = async (tag_id) => {
    setLoading(true);
    try {
      const res = await deleteTagById(tag_id);
      if (res) {
        setLoading(false);
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setEditState({
      name: "",
      id: "",
    });
  };

  return (
    <>
      <Helmet>
        <title>Tags | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      {isOpen && (
        <CreateTag
          id={editState.id}
          whatDoYouWant="update"
          tagName={editState.name}
          refreshState={() => setRefresh(!refresh)}
          isOpen={isOpen}
          handleClose={handleClose}
        />
      )}
      <div className="pt-2 pr-2 pl-2 lg:pl-0 pb-5">
        <div className="flex flex-col gap-3">
          <h1 className="font-medium text-xl">All Tags</h1>
          <div>
            <TextField
              id="name"
              label="Name"
              value={editState.name}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="name"
              onChange={(e) => {
                setEditState({ ...editState, name: e.target.value });
              }}
            />
          </div>
          <div>
            <Button
              disabled={!editState.name.length > 0}
              variant="contained"
              disableElevation
              onClick={(event) => {
                createOneTag(event);
              }}
            >
              Submit
            </Button>
          </div>
          {/* Showing all Tags */}
          <div>
            {tags.length > 0 ? (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  className="flex flex-wrap gap-2"
                >
                  {tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.name}
                      onClick={() => {
                        setEditState({
                          name: tag.name,
                          id: tag.id,
                        });
                        setIsOpen(!isOpen);
                      }}
                      onDelete={() => deleteTag(tag.id)}
                      variant="outlined"
                    />
                  ))}
                </Stack>
              </>
            ) : (
              <div className="">
                <h1 className="text-xl font-medium italic text-mediumgray">
                  No Tags Found...
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTags;
