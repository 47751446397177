import React, { useState } from "react";
import * as api from "../../../utils/api/apiClient";
import { toast } from "react-toastify";
import Loading from "../../Loading";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleForm = async (e) => {
    e.preventDefault();
    sendEmail();
  };

  const sendEmail = async () => {
    setIsLoading(true);
    const payload = {
      email,
      reset_password_ui_url: `${api.siteBaseURL}/auth/reset_password`,
    };
    try {
      const res = await api.forgotPassword(payload);
      toast.success(res.data.message);
      setIsLoading(false);
      setEmail("");
      navigate("/auth/login");
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message) {
        toast(err?.response?.data?.message);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-[95%] sm:w-1/2 md:w-1/3">
        <div className="text-center pb-5">
          <div className="flex justify-center items-center pb-2">
            <img src="/logo.webp" alt="logo" className="w-16 h-16" />
          </div>
          <h3 className="text-2xl font-semibold">Forgot Password</h3>
          <p className="text-sm text-mediumgray">
            Enter your email to reset password
          </p>
        </div>
        <form className="flex flex-col gap-5">
          <div>
            <TextField
              id="email"
              label="Email"
              autoComplete="off"
              type="email"
              value={email}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <LoadingButton
            color="primary"
            onClick={handleForm}
            loading={isLoading}
            disabled={email.length > 0 ? false : true}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>Submit</span>
          </LoadingButton>
        </form>
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
