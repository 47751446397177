import React, { useContext, useEffect } from "react";
import LoginForm from "../../components/ui/forms/LoginForm";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const { isLogin } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate("/", {
        replace: true,
      });
    }
  }, [isLogin, navigate]);

  return (
    <>
      <Helmet>
        <title>Sign In | Inflection Admin</title>
      </Helmet>
      <div>
        <LoginForm />
      </div>
    </>
  );
};

export default LoginPage;
