import React from "react";
import { useParams } from "react-router-dom";
import ProductVariant from "../../../components/ProductComponent/ProductVariant";
import { Helmet } from "react-helmet";
import ProductCreatingSteps from "../../../components/ProductCreatingSteps";

function CreateVariant() {
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>Variant Create | Inflection Admin</title>
      </Helmet>
      <div className="pt-2 pr-2 pl-2 sm:pl-0 pb-5">
        <ProductCreatingSteps step={3} />
        <div className="w-full sm:w-8/12 mx-auto">
          {/* Edit Product Form */}
          <ProductVariant productId={id} />
        </div>
      </div>
    </>
  );
}

export default CreateVariant;
