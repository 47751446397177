import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as api from "../../utils/api/apiClient";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FaSave } from "react-icons/fa";
import { calculateDiscountPrice } from "../../utils/PriceCalculate";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import Loading from "../../components/Loading";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import OrderStatusPopup from "../../components/ReUseFunctionComponent/OrderStatusPopup";

function OrderDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [orderForShippingPopup, setOrderForShippingPopup] = useState(false);
  const [orderSummary, setOrderSummary] = useState({
    subtotal: "00",
    discount: "00",
    shipping: "80",
    total: "00",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    status: "",
  });
  const status = [
    "pending",
    "processing",
    "shipped",
    "delivered",
    "returned",
    "refunded",
  ];

  const handleChange = (event) => {
    setSelectedStatus({ ...selectedStatus, status: event.target.value });
  };

  const changeOrderStatus = async (event) => {
    if (selectedStatus?.status === "shipped") {
      setOrderForShippingPopup(true);
    }
    setLoading(true);
    event.preventDefault();
    try {
      const res = await api.orderStatusUpdate(id, selectedStatus);
      if (res) {
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  useEffect(() => {
    const getOrderDetail = async () => {
      setLoading(true);
      try {
        const res = await api.getOneOrder(id);
        if (res) {
          setData(res.data.order);
          setSelectedStatus({
            ...selectedStatus,
            status: res.data?.order?.order_status,
          });

          if (res?.data?.order?.order_item) {
            let subtotalVal = 0;
            let discountVal = 0;
            res?.data?.order.order_item?.forEach((element) => {
              subtotalVal = subtotalVal + element.price * element.quantity;
              discountVal = discountVal + element.discount;
            });
            setOrderSummary({
              ...orderSummary,
              discount: discountVal,
              subtotal: subtotalVal,
              total:
                calculateDiscountPrice(subtotalVal, discountVal) +
                orderSummary.shipping * 1,
            });
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getOrderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refresh]);

  return (
    <>
      <Helmet>
        <title>Order Detail | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      {orderForShippingPopup && (
        <OrderStatusPopup
          handleClose={() => setOrderForShippingPopup(false)}
          isOpen={orderForShippingPopup}
          orderId={id}
          pageRefresh={() => setRefresh(!refresh)}
        />
      )}
      {/* <div className="flex justify-center items-center w-full h-screen md:hidden">
        <div className="text-center">
          <h1 className="text-2xl font-semibold">Order Detail</h1>
          <p>Showing on desktop</p>
        </div>
      </div> */}
      <div className="flex items-center justify-center my-3">
        <div className="flex flex-col gap-10 w-[768px] overflow-auto rounded-lg bg-white p-5">
          <div className="w-full">
            <div className="flex justify-between gap-5 font-semibold text-2xl">
              <h1>Order</h1>
              <h3>Customer</h3>
            </div>
            <div className="flex justify-between gap-5">
              <div>
                <h2 className="font-medium text-mediumgray text-sm">Date</h2>
                <p className="font-medium">
                  {formatDateTimeWithAMAndPM(data?.order_at)}
                </p>
              </div>
              <div>
                <h2 className="font-medium text-mediumgray text-sm">
                  Reference
                </h2>
                <p>{`#${data?.order_code}`}</p>
              </div>
              <div className="font-medium text-end">
                <p className="capitalize">{data?.order_customer_name}</p>
                <p className="font-normal lowercase italic underline text-blue">
                  {data?.customer_email}
                </p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 items-center">
            <div>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Status
                </InputLabel>

                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedStatus.status}
                  onChange={handleChange}
                  label="Status"
                  className="capitalize"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status.map((item, index) => (
                    <MenuItem key={index} value={item} className="capitalize">
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="font-medium text-base text-end">
              <h1 className="font-medium text-xl">Shipping Address</h1>
              <p className="text-wrap italic font-normal">{data?.address}</p>
            </div>
          </div>

          <div>
            <div className="border border-gray-200 p-4 rounded-lg space-y-4">
              <div className="hidden sm:grid sm:grid-cols-5">
                <div className="sm:col-span-2 text-xs font-medium  uppercase">
                  Item
                </div>
                <div className="text-start text-xs font-medium  uppercase">
                  Quantity
                </div>
                <div className="text-start text-xs font-medium  uppercase">
                  Discount
                </div>
                <div className="text-end text-xs font-medium  uppercase">
                  Amount
                </div>
              </div>

              <div className="hidden sm:block border-b"></div>
              {data?.order_item?.map((element, i) => (
                <div key={i} className="grid grid-cols-3 sm:grid-cols-5 gap-2">
                  <div className="col-span-full sm:col-span-2">
                    <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                      Item
                    </h5>
                    <p className="font-medium text-gray-800">
                      {element.product_name}
                    </p>
                  </div>
                  <div>
                    <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                      Qty
                    </h5>
                    <p className="text-gray-800">{element.quantity}</p>
                  </div>
                  <div>
                    <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                      Discount
                    </h5>
                    <p className="text-gray-800">{`${element.discount}%`}</p>
                  </div>
                  <div>
                    <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                      Amount
                    </h5>
                    <p className="sm:text-end text-gray-800">
                      {`₹${element.price}`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-5 flex sm:justify-end">
              <div className="w-full max-w-2xl sm:text-end space-y-2">
                <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Subtotal:
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {`₹${orderSummary.subtotal}`}
                    </dd>
                  </dl>

                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Total Discount
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {`${orderSummary.discount}%`}
                    </dd>
                  </dl>

                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Shipping
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {`₹${orderSummary.shipping}`}
                    </dd>
                  </dl>

                  <dl className="grid sm:grid-cols-5 gap-x-3">
                    <dt className="col-span-3 font-semibold text-gray-800">
                      Amount paid:
                    </dt>
                    <dd className="col-span-2 text-gray-500">
                      {`₹${orderSummary.total}`}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="text-end">
            <Button
              onClick={changeOrderStatus}
              variant="contained"
              startIcon={<FaSave />}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetail;
