import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import * as api from "../../utils/api/apiClient";
import Loading from "../Loading";

function AttributePopup({
  handleClose,
  isOpen,
  whatDoYouWant,
  categoryId,
  pageRefresh,
  attributeId,
  editAttributeName,
}) {
  const [loading, setLoading] = useState(false);
  const [attributeName, setAttributeName] = useState("");

  useEffect(() => {
    if (attributeId) {
      setAttributeName(editAttributeName);
    }
  }, [attributeId, editAttributeName]);

  const createAttribute = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await api.addAttribute({
        name: attributeName,
        category_id: +categoryId,
      });
      if (res) {
        pageRefresh();
        handleClose();
        setAttributeName("");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const updateAttribute = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await api.editAttribute(+attributeId, {
        name: attributeName,
      });
      if (res) {
        pageRefresh();
        handleClose();
        setAttributeName("");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleChange = (event) => {
    setAttributeName(event.target.value);
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {whatDoYouWant === "create" ? "Create Attribute" : "Edit Attribute"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form className="flex flex-col gap-5 w-full" action="#">
            <div>
              <TextField
                id="name"
                label="Name"
                value={attributeName}
                variant="outlined"
                fullWidth={true}
                size="small"
                color="info"
                required
                name="name"
                onChange={handleChange}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={attributeName.length > 0 ? false : true}
            onClick={(event) => {
              if (whatDoYouWant === "create") {
                createAttribute(event);
              } else {
                updateAttribute(event);
              }
            }}
            variant="contained"
            disableElevation
          >
            {whatDoYouWant === "create" ? "Submit" : "Save changes"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AttributePopup;
