import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductCreatingSteps from "../../../components/ProductCreatingSteps";
import Loading from "../../../components/Loading";
import * as api from "../../../utils/api/apiClient";
import Tags from "../../../components/ProductComponent/Tags";
import AttributeToProduct from "../../../components/ProductComponent/AttributeToProduct";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";

function AddTagsAttributes() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getOneProduct = async () => {
      setLoading(true);
      try {
        const res = await api.getProductById(id);
        if (res) {
          setLoading(false);
          setData(res.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getOneProduct();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Tags & Attributes | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className="pt-2 pr-2 pl-2 sm:pl-0 pb-5">
        <ProductCreatingSteps step={2} />
        <div className="w-full sm:w-8/12 mx-auto">
          <div className="flex flex-col gap-5">
            {/* Product Tags */}
            <Tags productId={id} />

            {/*Attribute Form */}
            <AttributeToProduct productId={id} categoryId={data.category_id} />
          </div>
          <div className="text-center py-10">
            <Button
              onClick={() => {
                navigate(`/product_variants/${id}`);
              }}
              variant="contained"
              disableElevation
            >
              Next Step
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTagsAttributes;
