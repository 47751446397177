import React, { useEffect, useState } from "react";
import * as api from "../../utils/api/apiClient";
import { Button } from "@mui/material";
import AttributeValueForm from "../ui/forms/AttributeValueForm";
import Loading from "../Loading";
import AttributePopup from "../ReUseFunctionComponent/AttributePopup";

function AttributeToProduct({ categoryId, productId }) {
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (categoryId) {
      const getAllAttributes = async () => {
        setLoading(true);
        try {
          const res = await api.getAttribute(categoryId);
          if (res) {
            setAttributes(res.data);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      getAllAttributes();
    }
  }, [categoryId, refresh]);

  return (
    <>
      {loading && <Loading />}
      {isOpen && (
        <AttributePopup
          whatDoYouWant={"create"}
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          categoryId={categoryId}
          pageRefresh={() => setRefresh(!refresh)}
        />
      )}
      <div className="w-full">
        {/*Attribute Form */}
        <div className="flex justify-between items-center gap-5">
          <h1 className="font-medium py-2">Add Attribute Values</h1>
          <Button
            variant="text"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Create Attribute
          </Button>
        </div>
        <div className="flex flex-col gap-5">
          {attributes.map((item, index) => (
            <AttributeValueForm
              stateRefresh={() => setRefresh(!refresh)}
              key={index}
              productId={productId}
              attributeId={item.id}
              attributeName={item.name}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default AttributeToProduct;
