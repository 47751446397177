import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as api from "../../../utils/api/apiClient";
import { toast } from "react-toastify";
import Loading from "../../Loading";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const ResetPasswordForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [isPassword, setIsPassword] = useState(true);
  const [isPasswordMissMatch, setIsPasswordMisMatch] = useState("");

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await api.resetPassword(params.token, {
        password,
      });

      toast.success(data.message);
      setIsLoading(false);
      setPassword("");
      setSecondPassword("");
      navigate("/auth/login");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(secondPassword?.length);
    if (password?.length && secondPassword?.length) {
      if (password !== secondPassword) {
        setIsPasswordMisMatch("Passwords do not match");
        return;
      } else if (password === secondPassword) {
        setIsPasswordMisMatch("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, secondPassword]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-[95%] sm:w-1/2 md:w-1/3">
        <div className="text-center pb-5">
          <div className="flex justify-center items-center pb-2">
            <img src="/logo.webp" alt="logo" className="w-16 h-16" />
          </div>
          <h3 className="text-2xl font-semibold">Reset your password</h3>
          <p className="text-sm text-mediumgray">
            if your token is exipred?&nbsp;
            <Link
              to="/auth/forgot_password"
              className="text-base text-blue duration-150 hover:underline"
            >
              click here
            </Link>
          </p>
        </div>
        <form className="flex flex-col gap-5">
          <div>
            <div className="flex flex-col gap-5">
              {/* password */}
              <TextField
                id="password"
                label="Password"
                autoComplete="off"
                type={isPassword ? "password" : "text"}
                value={password}
                variant="outlined"
                fullWidth={true}
                size="small"
                color="info"
                required
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* second password */}
              <div>
                <TextField
                  id="password"
                  label="Confirm Password"
                  autoComplete="off"
                  type={isPassword ? "password" : "text"}
                  value={secondPassword}
                  variant="outlined"
                  fullWidth={true}
                  size="small"
                  color="info"
                  required
                  name="password"
                  onChange={(e) => setSecondPassword(e.target.value)}
                />
                <p className="text-error text-sm">{isPasswordMissMatch}</p>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <FormControlLabel
                  onClick={() => setIsPassword(!isPassword)}
                  control={<Checkbox checked={isPassword ? false : true} />}
                  label={isPassword ? "Show Password" : "Hide Password"}
                />
              </div>
              <Link
                to="/auth/forgot_password"
                className="text-base text-blue duration-150 hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
          <LoadingButton
            color="primary"
            disabled={
              password?.length > 0 &&
              secondPassword?.length > 0 &&
              isPasswordMissMatch?.length <= 0
                ? false
                : true
            }
            onClick={handleForm}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>Submit</span>
          </LoadingButton>
        </form>
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
