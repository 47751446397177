import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../utils/api/apiClient";
import Loading from "../../components/Loading";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import { Button, Chip, Stack, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { MdEdit, MdDelete } from "react-icons/md";
import { Helmet } from "react-helmet";
import AddTagToProductPopup from "../../components/ProductComponent/AddTagToProductPopup";
import CreateVariantToProductPopup from "../../components/ProductComponent/CreateVariantToProductPopup";
import ProductEdit from "../../components/ProductComponent/ProductEdit";
import AddAttributeValueToProductPopup from "../../components/ProductComponent/AddAttributeValueToProductPopup";
import GalleryImage from "../../components/FullScreenSlider";

function ProductDetail() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [published, setPublished] = useState(true);
  const [addTagsToIsOpen, setAddTagsToIsOpen] = useState(false);
  const [createVariantToIsOpen, setCreateVariantToIsOpen] = useState(false);
  const [addAttributeValueToIsOpen, setAddAttributeValueToIsOpen] =
    useState(false);
  const [editVariantToIsOpen, setEditVariantToIsOpen] = useState({
    isOpen: false,
    data: {},
  });
  const [editProductToIsOpen, setEditProductToIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getOneProduct = async () => {
      setLoading(true);
      try {
        const res = await api.getProductById(id);
        if (res) {
          const obj = { ...res.data };
          obj.image_urls.push(res.data.thumbnail);
          setData(obj);
          setLoading(false);
          setPublished(res.data.is_published);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getOneProduct();
  }, [id, refresh]);

  const changeToPublic = async (isPublic) => {
    setLoading(true);
    try {
      const res = await api.updateProduct(data.id, {
        is_published: isPublic,
      });
      if (res) {
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setRefresh(!refresh);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const deleteVariant = async (v_id) => {
    setLoading(true);
    try {
      const res = await api.removeVariant(v_id);
      if (res) {
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const deleteProduct = async () => {
    try {
      const res = await api.deleteProduct(id);
      if (res) {
        navigate("/products");
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const removeTagFromProduct = async (tag_id) => {
    setLoading(true);
    try {
      const res = await api.deleteTagFromVariant(tag_id);
      if (res) {
        setLoading(false);
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const deleteAttributeValue = async (attribute_id) => {
    setLoading(true);
    try {
      const res = await api.removeAttributeValues(id, attribute_id);
      console.log(res);
      if (res) {
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Product Detail | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      {/* Add Tag To Product */}
      {addTagsToIsOpen && (
        <AddTagToProductPopup
          isOpen={addTagsToIsOpen}
          productId={id}
          handleClose={() => {
            setAddTagsToIsOpen(false);
            setRefresh(!refresh);
          }}
        />
      )}
      {/* Create Variant To Product */}
      {createVariantToIsOpen && (
        <CreateVariantToProductPopup
          isOpen={createVariantToIsOpen}
          categoryId={data?.category_id}
          productId={id}
          handleClose={() => {
            setCreateVariantToIsOpen(false);
            setRefresh(!refresh);
          }}
        />
      )}
      {/* Add Attribute Value To Product */}
      {addAttributeValueToIsOpen && (
        <AddAttributeValueToProductPopup
          isOpen={addAttributeValueToIsOpen}
          categoryId={data?.category_id}
          productId={id}
          handleClose={() => {
            setAddAttributeValueToIsOpen(false);
            setRefresh(!refresh);
          }}
        />
      )}
      {/* Edit Variant To Product */}
      {editVariantToIsOpen && (
        <CreateVariantToProductPopup
          isOpen={editVariantToIsOpen.isOpen}
          WhatDoYouWant={"edit"}
          data={editVariantToIsOpen.data}
          productId={id}
          categoryId={data?.category_id}
          handleClose={() => {
            setEditVariantToIsOpen({ isOpen: false, data: {} });
            setRefresh(!refresh);
          }}
        />
      )}

      {/* Edit Product To Product */}
      {editProductToIsOpen && (
        <ProductEdit
          isOpen={editProductToIsOpen}
          handleClose={() => {
            setEditProductToIsOpen(false);
            setRefresh(!refresh);
          }}
          productId={id}
          data={data}
        />
      )}
      <div className="md:flex w-full pt-2 pr-2 pl-2 lg:pl-0 pb-5">
        <div className="md:w-[45%] w-full">
          <div>
            {data?.image_urls?.length > 0 && (
              <GalleryImage
                arrayOfImage={data?.image_urls}
                videoUrl={data?.video_url}
              />
            )}
          </div>
        </div>
        {/* ********Product-Details******** */}
        <div className="md:w-[55%] w-full p-2 relative border-2 border-white duration-300 rounded">
          <div className="absolute flex gap-2 items-center top-0 right-0 text-2xl p-1">
            <MdEdit
              onClick={() => {
                setEditProductToIsOpen(true);
              }}
              className="text-blue cursor-pointer"
            />
            <MdDelete
              className="text-error cursor-pointer"
              onClick={() => {
                deleteProduct();
              }}
            />
          </div>
          <h2 className="text-2xl font-semibold ">{data?.name}</h2>
          <div className="flex flex-col gap-2 capitalize py-2">
            <p className="font-semibold flex items-center gap-1">
              note :
              <span className="text-gray-500 font-normal line-clamp-1 w-[85%] mx-auto">
                {data?.note}
              </span>
            </p>
            <p className="font-semibold flex items-center gap-1">
              metaKeyword :
              <span className="text-gray-500 font-normal line-clamp-1 w-[85%] mx-auto">
                {data?.meta_keywords}
              </span>
            </p>
            <p className="font-semibold flex items-center gap-1">
              categories Name :
              <span className="text-gray-500 font-normal">
                {data?.category_name}
              </span>
            </p>
            <p className="font-semibold flex items-center gap-1">
              Published :
              <Switch
                checked={published}
                onChange={() => {
                  changeToPublic(!published);
                  setPublished(!published);
                }}
              />
            </p>
          </div>

          {/* Attributes */}
          <div>
            <div className="flex justify-between gap-5 items-center">
              <h2 className="text-xl font-semibold py-2">Attributes :</h2>
              <Button
                variant="text"
                onClick={() => setAddAttributeValueToIsOpen(true)}
              >
                Add Attribute Value
              </Button>
            </div>
            <div className="flex flex-wrap items-center gap-4">
              {data?.attributes?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="relative group border-2 border-mediumgray border-opacity-40 bg-white hover:border-blue duration-300 rounded-md p-2"
                  >
                    <div className="absolute w-full h-full inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-70 transition duration-300 ease-in-out">
                      <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white opacity-0 group-hover:opacity-100">
                        <MdDelete
                          className="text-error cursor-pointer text-xl sm:text-2xl"
                          onClick={() => {
                            deleteAttributeValue(e.attribute_value_id);
                          }}
                        />
                      </button>
                    </div>
                    <ul>
                      <li className="font-semibold flex items-center gap-1">
                        Name :
                        <span className="text-gray-500 font-normal">
                          {e.name}
                        </span>
                      </li>
                      <li className="font-semibold flex items-center gap-1">
                        Value :
                        <span className="text-gray-500 font-normal">
                          {e.value}
                        </span>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>

          {/* variants */}
          <div>
            <div className="flex justify-between gap-5 items-center">
              <h2 className="text-xl font-semibold py-2">All Variants</h2>
              <Button
                variant="text"
                onClick={() => setCreateVariantToIsOpen(true)}
              >
                Create variant
              </Button>
            </div>
            <div className="flex flex-wrap items-center gap-4">
              {data?.variants?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="relative border-2 border-white hover:border-blue duration-300 rounded-md p-2 min-w-72 max-w-80"
                  >
                    <div>
                      <p className="font-semibold flex items-center gap-1">
                        Product Quantity :
                        <span className="text-gray-500 font-normal">
                          {e.quantity}
                        </span>
                      </p>
                      <p className="font-semibold flex items-center gap-1">
                        Name :
                        <span className="text-gray-500 font-normal">
                          {e.name}
                        </span>
                      </p>
                      <p className="font-semibold flex items-center gap-1">
                        Price :
                        <span className="text-gray-500 font-normal">
                          {e.price}
                        </span>
                      </p>
                      <p className="font-semibold flex items-center gap-1">
                        Discount :
                        <span className="text-gray-500 font-normal">
                          {`${e.discount}%`}
                        </span>
                      </p>
                      <p className="font-semibold flex items-center gap-1">
                        product code :
                        <span className="text-gray-500 font-normal">
                          {e.product_code}
                        </span>
                      </p>
                      <p className="font-semibold flex gap-1">
                        description:{" "}
                        <span
                          className="text-gray-500 font-normal text-wrap line-clamp-2"
                          dangerouslySetInnerHTML={{
                            __html: e.variant_description,
                          }}
                        />
                      </p>
                    </div>
                    <div className="absolute flex gap-2 items-center top-0 right-0 text-2xl p-1">
                      <MdEdit
                        onClick={() => {
                          setEditVariantToIsOpen({
                            ...editVariantToIsOpen,
                            isOpen: true,
                            data: e,
                          });
                        }}
                        className="text-blue cursor-pointer"
                      />
                      <MdDelete
                        className="text-error cursor-pointer"
                        onClick={() => {
                          deleteVariant(e.variant_id);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* **Product-Info* */}
          <div>
            <h2 className="text-xl font-semibold py-2">Product Information</h2>
            <ul className="flex flex-wrap gap-4 capitalize">
              <li className="border-2 p-2 font-normal border-white hover:border-blue cursor-pointer duration-300 rounded flex flex-col gap-0">
                uploaded:
                <span className="text-mediumgray text-sm">
                  {formatDateTimeWithAMAndPM(data?.uploaded_at)}
                </span>
              </li>
              <li className="border-2 p-2 font-normal border-white hover:border-blue cursor-pointer duration-300 rounded flex flex-col gap-0">
                created_at:
                <span className="text-mediumgray text-sm">
                  {formatDateTimeWithAMAndPM(data?.created_at)}
                </span>
              </li>
              <li className="border-2 p-2 font-normal border-white hover:border-blue cursor-pointer duration-300 rounded flex flex-col gap-0">
                updated_at:
                <span className="text-mediumgray text-sm">
                  {formatDateTimeWithAMAndPM(data?.updated_at)}
                </span>
              </li>
            </ul>
          </div>
          {/* *************** All Tags **********************/}
          <div>
            <div className="flex justify-between gap-5 items-center">
              <h2 className="text-xl font-semibold py-2">All Tags</h2>
              <Button variant="text" onClick={() => setAddTagsToIsOpen(true)}>
                Add Tags
              </Button>
            </div>
            <Stack direction="row" spacing={1} className="flex flex-wrap gap-2">
              {data?.tags?.map((tag, i) => {
                return (
                  <Chip
                    key={i}
                    label={tag.name}
                    onDelete={() => removeTagFromProduct(tag?.product_tag)}
                    variant="outlined"
                  />
                );
              })}
            </Stack>
          </div>
          {/* *************** description **********************/}
          <div>
            <h2 className="text-xl font-semibold py-2">Description</h2>
            <div
              className="text-mediumgray"
              dangerouslySetInnerHTML={{
                __html: data?.description,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
