import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import * as api from "../../utils/api/apiClient";
import Loading from "../Loading";

function OrderStatusPopup({ handleClose, isOpen, orderId, pageRefresh }) {
  const [shipmentsDetails, setShipmentsDetails] = useState({
    tracking_number: "",
    partner_name: "",
    tracking_url: "",
  });
  const [loading, setLoading] = useState(false);

  const updateShipmentDetails = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await api.orderUpdateToShipped(orderId, shipmentsDetails);
      if (res) {
        handleClose();
        pageRefresh();
        setShipmentsDetails({
          tracking_number: "",
          partner_name: "",
          tracking_url: "",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleChange = (event) => {
    setShipmentsDetails({
      ...shipmentsDetails,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth={"xs"}
        fullWidth
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {"Add Shipment Details"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form className="flex flex-col gap-5 w-full" action="#">
            <div>
              <TextField
                id="tracking_number"
                label="Tracking Number"
                value={shipmentsDetails.tracking_number}
                variant="outlined"
                fullWidth={true}
                size="small"
                color="info"
                required
                name="tracking_number"
                onChange={handleChange}
              />
            </div>
            <div>
              <TextField
                id="partner_name"
                label="Partner Name"
                value={shipmentsDetails.partner_name}
                variant="outlined"
                fullWidth={true}
                size="small"
                color="info"
                required
                name="partner_name"
                onChange={handleChange}
              />
            </div>
            <div>
              <TextField
                id="tracking_url"
                label="Tracking Url"
                value={shipmentsDetails.tracking_url}
                variant="outlined"
                fullWidth={true}
                size="small"
                color="info"
                name="tracking_url"
                onChange={handleChange}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !shipmentsDetails?.tracking_number?.length > 0 ||
              !shipmentsDetails?.partner_name?.length > 0
            }
            onClick={(event) => {
              updateShipmentDetails(event);
            }}
            variant="contained"
            disableElevation
          >
            {"Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default OrderStatusPopup;
