import React from "react";
import { Helmet } from "react-helmet";

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Not Found | Inflection Admin</title>
      </Helmet>
      <div>
        <h1>not found</h1>
      </div>
    </>
  );
}

export default NotFound;
