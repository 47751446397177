import React, { useState } from "react";
import * as api from "../../utils/api/apiClient";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Loading from "../Loading";

function ProductImages({ productId, title = "Submit", navigate }) {
  const [loading, setLoading] = useState(false);
  const [image_urls, setImage_urls] = useState([]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setImage_urls(selectedFiles);
  };

  const uploadImages = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const data = new FormData();
      image_urls.forEach((element) => {
        data.append("image_urls", element);
      });
      const res = await api.addImageToProduct(productId, data);
      if (res) {
        setImage_urls([]);
        setLoading(false);
        if (navigate) {
          navigate();
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="flex flex-col gap-2">
        <h6 className="font-medium">Add Images</h6>
        <input
          disabled={productId ? false : true}
          type="file"
          multiple
          onChange={handleFileChange}
          className="w-full border border-mediumgray border-opacity-50 hover:border-black rounded-md outline-none cursor-pointer p-1"
        ></input>
        <div className="text-center">
          <Button
            disabled={image_urls.length > 0 ? false : true}
            onClick={(event) => {
              uploadImages(event);
            }}
            variant="contained"
            disableElevation
          >
            {title}
          </Button>
        </div>
      </div>
    </>
  );
}

export default ProductImages;
