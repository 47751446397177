import React from "react";
import ForgotPasswordForm from "../../components/ui/forms/ForgotPasswordForm";
import { Helmet } from "react-helmet";

const ForgotPasswordPage = () => {
  return (
    <>
      <Helmet>
        <title>Forgot Password | Inflection Admin</title>
      </Helmet>
      <div>
        <ForgotPasswordForm />
      </div>
    </>
  );
};

export default ForgotPasswordPage;
