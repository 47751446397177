import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import Loading from "../../components/Loading";
import * as api from "../../utils/api/apiClient";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import { Helmet } from "react-helmet";

function AllCategories() {
  // TODO: If CSV Export is needed in future then Visit this link https://www.material-react-table.com/docs/examples/export-csv
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      try {
        const res = await api.getAllCategories();
        if (res) {
          const array = [];
          res?.data?.forEach((element) => {
            const obj = { ...element };
            obj.created_at = formatDateTimeWithAMAndPM(element?.created_at);
            obj.image = (
              <Avatar
                src={element.image}
                alt="logo"
                sx={{ width: 50, height: 50 }}
              />
            );
            array.push(obj);
          });
          setData(array);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getCategories();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "image",
        header: "Thumbnail",
        size: 0,
        filter: false,
      },
      {
        accessorKey: "name",
        header: "Category Name",
        size: 100,
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 200,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/categories/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <>
      <Helmet>
        <title>Categories | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className="pt-2 pr-2 pl-2 lg:pl-0 pb-5">
        <div className="flex justify-between items-center pb-1.5">
          <h1 className="font-medium text-2xl">All Categories</h1>
          <Button variant="text" onClick={() => navigate("/categories/create")}>
            Create Category
          </Button>
        </div>
        <MaterialReactTable table={table} />
      </div>
    </>
  );
}

export default AllCategories;
