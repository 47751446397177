import React, { useEffect, useState } from "react";
import ProductForm from "../../../components/ProductComponent/ProductForm";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ProductCreatingSteps from "../../../components/ProductCreatingSteps";

function CreateProduct() {
  const [productId, setProductId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      navigate(`/product_images/${productId}`);
    }
  }, [productId, navigate]);
  return (
    <>
      <Helmet>
        <title>Products Create | Inflection Admin</title>
      </Helmet>
      <div className="pt-2 pr-2 pl-2 sm:pl-0 pb-5">
        <ProductCreatingSteps step={0} />
        <div className="w-full sm:w-8/12 mx-auto">
          {/* Create Product Form */}
          <ProductForm productId={(id) => setProductId(id)} />
        </div>
      </div>
    </>
  );
}

export default CreateProduct;
