import axios from "axios";
import { getCookie } from "../api/cookies";

export const baseURL = process.env.REACT_APP_BASE_URL;

export const siteBaseURL = process.env.REACT_APP_SITE_URL;

let apiClient = axios.create({
  baseURL,
  withCredentials: false,
  timeout: 30000,
  headers: {
    authorization: `Bearer ${getCookie("access_token")}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const setAuthHeader = (tokenName) => {
  apiClient = axios.create({
    baseURL,
    withCredentials: false,
    timeout: 30000,
    headers: {
      authorization: `Bearer ${getCookie(tokenName)}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
// ---------------- Form Data axios configurations -----------
let formDataApiClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${getCookie("access_token")}`,
  },
  timeout: 60000,
});

const setFormDataAuthHeader = () => {
  formDataApiClient = axios.create({
    baseURL,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getCookie("access_token")}`,
    },
    timeout: 60000,
  });
};

// ------------------ User -----------------------

const getAllUsers = (limit, page) => {
  return apiClient.get(`/users?limit=${limit}&page=${page}`);
};

const signUp = (payload) => {
  return apiClient.post("/users/signup", payload);
};
const login = (payload) => {
  return apiClient.post("/users/login", payload);
};
const resetPassword = (token, payload) => {
  return apiClient.patch(`/users/reset_password/${token}`, payload);
};
const forgotPassword = (payload) => {
  return apiClient.post("/users/forgot_password", payload);
};

const renewAccessToken = () => {
  return apiClient.get("/users/renew_token");
};

const getMyProfile = () => {
  return apiClient.get("/users/profiles/my");
};

const logOut = () => {
  return apiClient.delete("/users/logout");
};

// ------------------ Category -----------------------

const createCategory = (payload) => {
  return formDataApiClient.post("/categories", payload);
};

const getAllCategories = () => {
  return apiClient.get("/categories/list");
};
const getCategoryById = (id) => {
  return apiClient.get(`/categories/${id}`);
};

const updateCategory = (id, payload) => {
  return formDataApiClient.patch(`/categories/${id}`, payload);
};

const deleteCategory = (id) => {
  return apiClient.delete(`/categories/${id}`);
};

// ------------------ Product -----------------------

const createProduct = (payload) => {
  return formDataApiClient.post("/products", payload);
};

const getAllProducts = (limit = 5000, page = 1) => {
  return apiClient.get(`/products/only?limit=${limit}&page=${page}`);
};

const getProductById = (id) => {
  return apiClient.get(`/products/${id}`);
};
const deleteProduct = (id) => {
  return apiClient.delete(`/products/${id}`);
};

const updateProduct = (id, payload) => {
  return formDataApiClient.patch(`/products/${id}`, payload);
};
const addImageToProduct = (id, payload) => {
  return formDataApiClient.post(`/products/images/${id}`, payload);
};
const addTagToProduct = (payload) => {
  return apiClient.post("/product_tags", payload);
};
const getTagsToProduct = (id) => {
  return apiClient.get(`/product_tags/${id}`);
};
const deleteTagFromVariant = (id) => {
  return apiClient.delete(`/product_tags/${id}`);
};
const addAttribute = (payload) => {
  return apiClient.post("/attributes", payload);
};
const addAttributeValues = (id, payload) => {
  return apiClient.post(`/products/attributes/${id}`, payload);
};
const removeAttributeValues = (product_id, attribute_id) => {
  return apiClient.delete(
    `/products/attributes/${product_id}?attr_val_id=${attribute_id}`
  );
};
const editAttribute = (id, payload) => {
  return apiClient.patch(`/attributes/${id}`, payload);
};
const getAttribute = (cat_id) => {
  return apiClient.get(`/attributes/${cat_id}`);
};
const removeAttribute = (id) => {
  return apiClient.delete(`/attributes/${id}`);
};

// ------------------ Variant -----------------------
const createVariant = (payload) => {
  return formDataApiClient.post("/variants", payload);
};

const editVariant = (id, payload) => {
  return formDataApiClient.patch(`/variants/${id}`, payload);
};

const getAllVariant = (product_id) => {
  return formDataApiClient.get(`/variants/product/${product_id}`);
};

const removeVariant = (id) => {
  return apiClient.delete(`/variants/${id}`);
};
// ------------------ Tags -----------------------

const getAllTags = () => {
  return apiClient.get("/tags");
};

const createTag = (payload) => {
  return apiClient.post("/tags", payload);
};

const updateTag = (id, payload) => {
  return apiClient.patch(`/tags/${id}`, payload);
};

const deleteTagById = (id) => {
  return apiClient.delete(`/tags/${id}`);
};

// ------------------ Orders -----------------------
const getAllOrders = (limit = 5000, page = 1) => {
  return apiClient.get(`/orders/list?limit=${limit}&page=${page}`);
};

const getOneOrder = (id) => {
  return apiClient.get(`/oneorder/${id}`);
};
const orderStatusUpdate = (id, payload) => {
  return apiClient.patch(`/orders/status/${id}`, payload);
};
const orderUpdateToShipped = (id, payload) => {
  return apiClient.patch(`/shipments/manual/${id}`, payload);
};

// ------------------ reviews -----------------------
const getAllReviews = (limit, page) => {
  return apiClient.get(`/reviews?limit=${limit}&page=${page}`);
};

export {
  setAuthHeader,
  signUp,
  login,
  getMyProfile,
  logOut,
  setFormDataAuthHeader,
  addAttribute,
  addTagToProduct,
  getTagsToProduct,
  deleteProduct,
  getAllOrders,
  getOneOrder,
  orderStatusUpdate,
  getAllReviews,
  getAllUsers,
  deleteTagFromVariant,
  renewAccessToken,
  forgotPassword,
  getAttribute,
  removeAttribute,
  resetPassword,
  createCategory,
  removeVariant,
  getAllCategories,
  editAttribute,
  getCategoryById,
  updateCategory,
  deleteCategory,
  createProduct,
  removeAttributeValues,
  getAllProducts,
  getProductById,
  updateProduct,
  orderUpdateToShipped,
  addImageToProduct,
  createTag,
  editVariant,
  getAllTags,
  addAttributeValues,
  deleteTagById,
  updateTag,
  createVariant,
  getAllVariant,
};
