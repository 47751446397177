import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import * as api from "../../../utils/api/apiClient";
import { toast } from "react-toastify";
import Loading from "../../Loading";

function AttributeValueForm({
  attributeId,
  productId,
  attributeName,
  stateRefresh,
}) {
  const [attributeValue, setAttributeValue] = useState("");
  const [loading, setLoading] = useState(false);

  const AddValueToProduct = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const payload = {
        values: [
          {
            attribute_id: attributeId,
            value: attributeValue,
          },
        ],
      };
      const res = await api.addAttributeValues(productId, payload);
      if (res) {
        setAttributeValue("");
        stateRefresh();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
  return (
    <>
      {loading && <Loading />}
      <div className="flex items-center gap-5">
        <form action="#" className="flex flex-col gap-5 w-full">
          <div>
            <TextField
              id="value"
              label={attributeName}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              value={attributeValue}
              required
              name="value"
              onChange={(event) => {
                setAttributeValue(event.target.value);
              }}
            />
          </div>
        </form>
        <Button
          disabled={!attributeValue?.length > 0}
          onClick={(event) => {
            AddValueToProduct(event);
          }}
          variant="contained"
          disableElevation
        >
          Add
        </Button>
      </div>
    </>
  );
}

export default AttributeValueForm;
