import React, { useEffect, useMemo, useState } from 'react'
import { formatDateTimeWithAMAndPM } from '../../utils/DateCalculate'
import { getOrders } from '../../utils/functions/api-functions'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import Loading from '../../components/Loading'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { Helmet } from 'react-helmet'

function Orders() {
  // TODO: If CSV Export is needed in future then Visit this link https://www.material-react-table.com/docs/examples/export-csv
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const navigate = useNavigate()

  console.log(data)

  useEffect(() => {
    setLoading(true)
    getOrders(5000, 1)
      .then((res) => {
        setLoading(false)
        const array = []
        res.forEach((element) => {
          const obj = { ...element }
          obj.order_at = formatDateTimeWithAMAndPM(element.order_at)
          obj.profile_photo_url = (
            <Avatar
              src={element.profile_photo_url}
              alt='logo'
              className='w-12 h-12 rounded-full'
            />
          )
          obj.created_at = formatDateTimeWithAMAndPM(element.created_at)
          obj.updated_at = formatDateTimeWithAMAndPM(element.updated_at)
          array.push(obj)
        })
        setData(array)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'order_at', //access nested data with dot notation
        header: 'Date',
        size: 150,
      },
      {
        accessorKey: 'profile_photo_url',
        header: 'Profile',
        size: 0,
        filter: false,
      },
      {
        accessorKey: 'full_name',
        header: 'Full Name',
        size: 150,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 200,
      },
      {
        accessorKey: 'order_status',
        header: 'Status',
        size: 50,
      },
      {
        accessorKey: 'total_price',
        header: 'Total',
        size: 50,
      },
      {
        accessorKey: 'order_code',
        header: 'Reference',
        size: 50,
      },
    ],
    []
  )

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/orders/${row.original.id}`)
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  })

  return (
    <>
      <Helmet>
        <title>Orders | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className='pt-2 pr-2 pl-2 lg:pl-0 pb-5'>
        <MaterialReactTable table={table} />
      </div>
    </>
  )
}

export default Orders
