import React from "react";
import { Link, useLocation } from "react-router-dom";

const Heading = (props) => {
  const { link, logout, title, icon, clickToCloseDrawer } = props;
  const location = useLocation();
  return (
    <div>
      <Link
        to={props.link}
        onClick={() => {
          if (logout) {
            logout();
          }
          if (clickToCloseDrawer) {
            clickToCloseDrawer();
          }
        }}
        className={`px-3 py-4 flex items-center justify-between hover:text-blue hover:cursor-pointer ${
          location.pathname === link
            ? "bg-lightPurple text-blue"
            : "text-mediumgray"
        }`}
      >
        <div className="flex items-center gap-3">
          <div className="text-xl sm:text-2xl text-blue hover:cursor-default">
            {icon}
          </div>
          <div>
            <h3 className="font-medium uppercase">{title}</h3>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Heading;
