import React, { useEffect, useState } from "react";
import Dashboard from "../../components/ui/buttons/Dashboard";
import {
  getOrders,
  getReviews,
  getUsers,
} from "../../utils/functions/api-functions";
import { FaShoppingCart, FaDollarSign } from "react-icons/fa";
import { CgUserAdd } from "react-icons/cg";
import { MdMessage } from "react-icons/md";
import userPic from "../../assets/profile.png";
import Chart from "../../assets/chrt.png";
import { formatDateTime } from "../../utils/DateCalculate";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [reviewRating, setReviewRating] = useState([]);
  const [users, setUsers] = useState([]);
  const [starsNumber, setStarsNumber] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getOrders()
      .then((res) => {
        setLoading(false);
        const array = [];
        for (let i = 0; i < res.length; i++) {
          if (res[i].order_status === "pending") {
            array.push(res[i]);
          }
        }
        setOrders(array);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    getUsers()
      .then((res) => {
        setLoading(false);
        setUsers(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getReviews()
      .then((res) => {
        setReviewRating(res);
        setLoading(false);
        const stars = [];
        for (let i = 0; i < res; i++) {
          stars.push("i");
        }
        setStarsNumber(stars);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pt-2 pr-2 pl-2 sm:pl-0 pb-5">
        <div>
          <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5">
            <div>
              <Dashboard
                data={"$5,924"}
                title={"Monthly Revenue"}
                icon={<FaDollarSign />}
              />
            </div>
            <div>
              <Dashboard
                data={orders}
                title={"New Orders"}
                icon={<FaShoppingCart />}
                link={"/orders"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <img src={Chart} alt="Chart" className="w-full pt-2" />
            <div className="flex flex-col gap-2 bg-white rounded p-2">
              <h6 className="text-mediumgray text-xl font-medium">
                Pending Orders
              </h6>
              {orders.map((order, index) => (
                <div
                  onClick={() => navigate(`/orders/${order.id}`)}
                  key={index}
                  className="flex gap-2 justify-between items-center px-3 py-2 rounded border-2 cursor-pointer"
                >
                  <div className="flex gap-2 items-center">
                    <div>
                      <img
                        src={userPic}
                        alt="profile"
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <div className="flex flex-col gap-0 font-medium">
                      <p className="text-mediumgray">
                        {formatDateTime(order?.order_at)}
                      </p>
                      <h1 className="text-black text-opacity-70 text-sm">
                        {`by ${order?.full_name} ${""}`}
                      </h1>
                    </div>
                  </div>
                  <div>
                    <p className="font-medium text-lg">{`₹${order?.total_price}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-5">
          <div>
            <Dashboard
              data={reviewRating}
              title={"Users Review"}
              icon={<MdMessage />}
              link={"/reviews"}
            >
              <div className="flex flex-col gap-2 bg-white">
                {reviewRating?.map((review, index) => (
                  <div
                    key={index}
                    className="flex gap-2 items-center px-3 py-2"
                  >
                    <div>
                      <img
                        src={
                          review.profile_photo_url
                            ? review.profile_photo_url
                            : userPic
                        }
                        alt="profile"
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <div>
                      {starsNumber?.map((star, index) => (
                        <span key={index}>{`⭐️`}</span>
                      ))}
                      <p className="font-medium text-black text-opacity-70 max-w-72">
                        {review?.review}
                      </p>
                      <h6 className="text-xs capitalize">
                        {review?.full_name}
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </Dashboard>
          </div>
          <div>
            <Dashboard
              data={users}
              title={"New Customers"}
              icon={<CgUserAdd />}
              link={"/users"}
            >
              <div className="flex flex-col gap-2 bg-white">
                {users?.map((user, index) => (
                  <div
                    key={index}
                    className="flex gap-2 items-center px-3 py-2"
                  >
                    <div>
                      <img
                        src={
                          user.profile_photo_url
                            ? user.profile_photo_url
                            : userPic
                        }
                        alt="profile"
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <h1 className="font-medium text-black text-opacity-70">
                      {user?.full_name}
                    </h1>
                  </div>
                ))}
              </div>
            </Dashboard>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
