import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import ProtectedRoute from '../src/components/PrivateRoutes/ProtectedRoute.jsx'
import NotFound from '../src/pages/NotFound'
import Navbar from './components/app/Navbar.jsx'
import Sidebar from './components/app/Sidebar.jsx'
import Home from './pages/home/Home.jsx'
import Login from './pages/auth/Login.jsx'
import ForgotPassword from './pages/auth/ForgotPassword.jsx'
import ResetPassword from './pages/auth/ResetPassword.jsx'
import { AiFillHeart } from 'react-icons/ai'
import AllCategories from './pages/categories/AllCategories.jsx'
import AllProduct from './pages/products/AllProduct.jsx'
import AllTags from './pages/tags/AllTags.jsx'
import Users from './pages/users/Users.jsx'
import Orders from './pages/orders/index.jsx'
import OrderDetail from './pages/orders/orderDetail.jsx'
import ToastContainer from '../src/components/ToastContainer.jsx'
import { getCookie, setCookie } from './utils/api/cookies.js'
import * as api from './utils/api/apiClient.js'
import Reviews from './pages/reviews/Reviews.jsx'
import CreateProduct from './pages/products/new product/CreateProduct.jsx'
import CreateCategory from './pages/categories/new Category/CreateCategory.jsx'
import CategoryDetail from './pages/categories/CategoryDetail.jsx'
import { AuthContext } from './context/authContext.js'
import SignUp from './pages/auth/Signup.jsx'
import ProductDetail from './pages/products/ProductDetail.jsx'
import CreateVariant from './pages/products/new product/CreateVariant.jsx'
import AddTagsAttributes from './pages/products/new product/AddTagsAttributes.jsx'
import AddImages from './pages/products/new product/AddImages.jsx'

const App = () => {
  const navigate = useNavigate()
  const [like, setLike] = useState(false)
  const [isLogin, setIsLogin] = useState(() => {
    const access_token = getCookie('access_token')
    if (access_token == null || access_token.length <= 0) {
      return false
    }
    api.setAuthHeader('access_token')
    return true
  })
  const [profileDetails, setProfileDetails] = useState({})

  const renewToken = async () => {
    try {
      const { data } = await api.renewAccessToken()
      const access_token = data.access_token
      const accessTokenExpiresAt = Date.parse(data.access_token_expires_at)
      const currentMilies = Date.now()
      setCookie(
        'access_token',
        access_token,
        parseInt((accessTokenExpiresAt - currentMilies) / 1000)
      )
      setCookie('access_token', access_token)
      api.setAuthHeader('access_token')
      api.setFormDataAuthHeader('access_token')
      setIsLogin(true)
    } catch (err) {
      console.log(err)
      setIsLogin(false)
    }
  }

  const fetchMyProfile = async () => {
    try {
      const { data } = await api.getMyProfile()
      setProfileDetails(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const checkLogin = () => {
      const accessToken = getCookie('access_token')
      const refreshToken = getCookie('refresh_token')

      if (accessToken || accessToken !== null) {
        return
      }

      if (refreshToken && refreshToken !== null) {
        api.setAuthHeader('refresh_token')
        renewToken()
        return
      } else {
        if (isLogin) {
          navigate(0)
        }
      }
    }

    const loginInterval = setInterval(checkLogin, 1000)
    if (isLogin) {
      fetchMyProfile()
    }
    return () => clearInterval(loginInterval)
  }, [isLogin, navigate])

  return (
    <AuthContext.Provider
      value={{ isLogin, setIsLogin, profileDetails, setProfileDetails }}
    >
      <Navbar />
      <div className='flex gap-2 bg-lightPurple/40'>
        <div className='hidden lg:block sticky top-[60px] h-[calc(100vh-60px)] w-[30%] xl:w-[15%] overflow-y-auto'>
          <Sidebar />
        </div>
        <div className='w-full'>
          <Routes>
            <Route path='/auth/login' element={<Login />} />
            <Route path='/auth/signup' element={<SignUp />} />
            <Route path='/auth/forgot_password' element={<ForgotPassword />} />
            <Route
              path='/auth/reset_password/:token'
              element={<ResetPassword />}
            />
            <Route path='*' element={<NotFound />} />
            {/* Protected Route is here */}
            <Route element={<ProtectedRoute />}>
              <Route path='/' element={<Home />} />
              <Route path='/categories' element={<AllCategories />} />
              <Route path='/categories/create' element={<CreateCategory />} />
              <Route path='/categories/:id' element={<CategoryDetail />} />
              <Route path='/products' element={<AllProduct />} />
              <Route path='/products/:id' element={<ProductDetail />} />
              {/* Step 1 */}
              <Route path='/product_create' element={<CreateProduct />} />
              {/* Step 2 */}
              <Route path='/product_images/:id' element={<AddImages />} />
              {/* Step 3 */}
              <Route
                path='/product_tags_attributes/:id'
                element={<AddTagsAttributes />}
              />
              {/* Step 4 */}
              <Route path='/product_variants/:id' element={<CreateVariant />} />
              <Route path='/orders' element={<Orders />} />
              <Route path='/orders/:id' element={<OrderDetail />} />
              <Route path='/tags' element={<AllTags />} />
              <Route path='/users' element={<Users />} />
              <Route path='/reviews' element={<Reviews />} />
            </Route>
          </Routes>
        </div>
      </div>
      <footer className='border-t'>
        <p className='text-center text-mediumgray p-5 fill-error'>
          Copyright ©2024 All rights reserved | This template is made with by{' '}
          <span onClick={() => setLike(!like)} className='cursor-pointer'>
            <AiFillHeart
              className={`inline ${like ? 'fill-error' : 'fill-mediumgray'}`}
            />{' '}
            <a
              href='https://inflection.org/'
              target='_blank'
              rel='noreferrer'
              className='hover:text-blue'
            >
              Inflection ORG
            </a>
          </span>
        </p>
      </footer>
      <ToastContainer />
    </AuthContext.Provider>
  )
}

export default App
