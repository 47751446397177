import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Description({ changeData, data }) {
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
      ],
    },
  };

  return (
    <ReactQuill
      className="w-full h-full"
      modules={modules}
      theme="snow"
      value={data}
      onChange={(e) => changeData(e)}
    />
  );
}

export default Description;
