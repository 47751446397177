import React, { useEffect, useMemo, useState } from "react";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import { getProducts } from "../../utils/functions/api-functions";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import Loading from "../../components/Loading";
import { Helmet } from "react-helmet";

function AllProduct() {
  // TODO: If CSV Export is needed in future then Visit this link https://www.material-react-table.com/docs/examples/export-csv
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    // TODO: Apply Styles in future...
    getProducts()
      .then((res) => {
        setLoading(false);
        const array = [];
        res.forEach((element) => {
          const obj = { ...element };
          obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
          obj.thumbnail = (
            <Avatar
              src={element.thumbnail}
              alt="logo"
              sx={{ width: 50, height: 50 }}
            />
          );
          obj.is_published = element.is_published ? "Yes" : "No";
          obj.price = "₹" + element.price;
          obj.quantity = "QTY: " + element.quantity;
          array.push(obj);
        });
        setData(array);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 50,
      },
      {
        accessorKey: "thumbnail",
        header: "Thumbnail",
        size: 50,
        filter: false,
      },
      {
        accessorKey: "name",
        header: "Product Name",
        size: 150,
      },
      {
        accessorKey: "note",
        header: "Note",
        size: 300,
      },
      {
        accessorKey: "is_published",
        header: "Public",
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        navigate(`/products/${row.original.id}`);
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <>
      <Helmet>
        <title>Products | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className="pt-2 pr-2 pl-2 lg:pl-0 pb-5">
        <div className="flex justify-between items-center pb-1.5">
          <h1 className="font-medium text-2xl">All Products</h1>
          <Button variant="text" onClick={() => navigate("/product_create")}>
            Create Product
          </Button>
        </div>
        <MaterialReactTable table={table} />
      </div>
    </>
  );
}

export default AllProduct;
