import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ProductVariant from "./ProductVariant";

function CreateVariantToProductPopup({
  isOpen,
  handleClose,
  productId,
  data,
  WhatDoYouWant,
}) {
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth={"lg"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {"Create Variant and Add to Product"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <ProductVariant
            WhatDoYouWant={WhatDoYouWant}
            productId={productId}
            handleClose={handleClose}
            data={data}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default CreateVariantToProductPopup;
