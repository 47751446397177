import React, { useEffect, useState } from "react";
import * as api from "../../utils/api/apiClient";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { MdEdit, MdDelete } from "react-icons/md";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";
import AttributePopup from "../../components/ReUseFunctionComponent/AttributePopup";
import CreateCategory from "../../components/ReUseFunctionComponent/CreateCategory";

function CategoryDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenForCategories, setIsOpenForCategories] = useState(false);
  const [whatDoYouWant, setWhatDoYouWant] = useState("create"); // create, edit for attribute
  const [attribute, setAttribute] = useState({
    name: "",
    id: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      try {
        const res = await api.getCategoryById(id);
        if (res) {
          setLoading(false);
          setData(res.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getCategories();

    const categoryAttributes = async () => {
      setLoading(true);
      try {
        const res = await api.getAttribute(id);
        if (res) {
          setAttributes(res.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
    };
    categoryAttributes();
  }, [id, refresh]);

  const deleteCategory = async () => {
    setLoading(true);
    try {
      const res = await api.deleteCategory(id);
      if (res) {
        navigate("/categories");
        setRefresh(!refresh);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const removeAttribute = async (tag_id) => {
    setLoading(true);
    try {
      const res = await api.removeAttribute(tag_id);
      if (res) {
        setLoading(false);
        const array = [];
        attributes.forEach((attribute) => {
          if (attribute.id !== tag_id) {
            array.push(attribute);
          }
        });
        setAttributes(array);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Category Detail | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      {isOpen && (
        <AttributePopup
          whatDoYouWant={whatDoYouWant}
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
            setAttribute({ name: "", id: "" });
          }}
          categoryId={id}
          pageRefresh={() => setRefresh(!refresh)}
          attributeId={attribute.id}
          editAttributeName={attribute.name}
        />
      )}
      {isOpenForCategories && (
        <CreateCategory
          data={data}
          isOpen={isOpenForCategories}
          refreshState={() => setRefresh(!refresh)}
          handleClose={() => setIsOpenForCategories(false)}
          whatDoYouWant="edit"
        />
      )}
      <div>
        <div className="md:flex gap-5 pt-2 pr-2 pl-2 lg:pl-0 pb-5">
          <div className="md:w-[40%] w-full">
            <img
              src={data?.image}
              alt="pic"
              className="w-full h-auto rounded-l"
            />
          </div>
          <div className="md:w-[60%] w-full relative border-2 border-white rounded">
            <div className="absolute flex gap-2 items-center top-0 right-0 text-2xl p-1">
              <MdEdit
                onClick={() => {
                  setIsOpenForCategories(true);
                }}
                className="text-blue cursor-pointer"
              />
              <MdDelete
                className="text-error cursor-pointer"
                onClick={() => {
                  deleteCategory();
                }}
              />
            </div>
            <div className="flex flex-col gap-3 px-3">
              <h2 className="text-xl font-medium capitalize">{data?.name}</h2>
              <div className="border-2 border-white rounded pl-2 pb-2">
                <div className="flex justify-between items-center gap-5">
                  <p className="font-medium pb-1.5 text-lg">All attributes</p>
                  <Button
                    variant="text"
                    onClick={() => {
                      setWhatDoYouWant("create");
                      setIsOpen(true);
                    }}
                  >
                    Create Attribute
                  </Button>
                </div>
                {attributes?.length > 0 && (
                  <Stack direction="row" spacing={2}>
                    {attributes?.map((item) => (
                      <Chip
                        key={item?.id}
                        label={item?.name}
                        variant="outlined"
                        className="text-w"
                        onClick={() => {
                          setWhatDoYouWant("edit");
                          setAttribute({
                            ...attribute,
                            name: item.name,
                            id: item.id,
                          });
                          setIsOpen(true);
                        }}
                        onDelete={() => {
                          removeAttribute(item.id);
                        }}
                      />
                    ))}
                  </Stack>
                )}
              </div>
              <ul className="md:w-[50%]">
                <li className="font-medium">Description :</li>
                <li>{data?.description}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryDetail;
