import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import * as api from "../../utils/api/apiClient";
import Loading from "../Loading";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CreateTag({
  isOpen,
  handleClose,
  refreshState,
  id,
  tagName,
  whatDoYouWant = "create",
}) {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({ name: tagName || "" });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const createOneTag = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const res = await api.createTag(formData);
      // console.log(res.data);
      if (res) {
        setFormData({ name: "" });
        refreshState();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const editTag = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const res = await api.updateTag(id, formData);
      if (res) {
        setFormData({ name: "" });
        refreshState();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {whatDoYouWant === "create" ? "Create Tag" : "Edit Tag"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form action="#">
            <div>
              <TextField
                id="name"
                label="Name"
                value={formData.name}
                variant="outlined"
                fullWidth={true}
                size="small"
                color="info"
                required
                name="name"
                onChange={handleChange}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!formData.name.length > 0}
            variant="contained"
            disableElevation
            onClick={(event) => {
              if (whatDoYouWant === "create") {
                createOneTag(event);
              } else {
                editTag(event);
              }
              handleClose();
            }}
          >
            {whatDoYouWant === "create" ? "Submit" : "Save changes"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
