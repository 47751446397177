import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as api from "../../../utils/api/apiClient";
import { AuthContext } from "../../../context/authContext";
import { toast } from "react-toastify";
import { setCookie } from "../../../utils/api/cookies";
import Loading from "../../Loading";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

const LoginForm = () => {
  const { setIsLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isPassword, setIsPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await api.login(formData);

      const accesTokenExpiresAt = Date.parse(data.access_token_expires_at);
      const refreshTokenExpiresAt = Date.parse(data.refresh_token_expires_at);
      const currentMilies = Date.now();

      const access_token = data.access_token;
      const refresh_token = data.refresh_token;
      setCookie(
        "access_token",
        access_token,
        parseInt((accesTokenExpiresAt - currentMilies) / 1000)
      );
      setCookie(
        "refresh_token",
        refresh_token,
        parseInt((refreshTokenExpiresAt - currentMilies) / 1000)
      );
      api.setAuthHeader("access_token");
      api.setFormDataAuthHeader("access_token");
      setIsPassword(true);
      setIsLogin(true);
      setIsLoading(false);
      toast.success("Login Success");
      setFormData({ email: "", password: "" });
      navigate("/", { replace: true });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-[95%] sm:w-1/2 md:w-1/3">
        <div className="text-center pb-5">
          <div className="flex justify-center items-center pb-2">
            <img src="/logo.webp" alt="logo" className="w-16 h-16" />
          </div>
          <h3 className="text-2xl font-semibold">Login to your account</h3>
          <p className="text-sm text-mediumgray">
            Don't have an account yet?&nbsp;
            <Link
              to="/auth/signup"
              className="text-base text-blue duration-150 hover:underline"
            >
              Sign Up
            </Link>
          </p>
        </div>
        <form className="flex flex-col gap-5">
          <div>
            <TextField
              id="email"
              label="Email"
              autoComplete="off"
              type="email"
              value={formData.email}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="email"
              onChange={handelChange}
            />
          </div>
          <div>
            <TextField
              id="password"
              label="Password"
              autoComplete="off"
              type={isPassword ? "password" : "text"}
              value={formData.password}
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              required
              name="password"
              onChange={handelChange}
            />
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <FormControlLabel
                  onClick={() => setIsPassword(!isPassword)}
                  control={<Checkbox checked={isPassword ? false : true} />}
                  label={isPassword ? "Show Password" : "Hide Password"}
                />
              </div>
              <Link
                to="/auth/forgot_password"
                className="text-base text-blue duration-150 hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
          <LoadingButton
            color="primary"
            disabled={
              formData.email?.length > 0 && formData.password?.length > 0
                ? false
                : true
            }
            onClick={handleForm}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            <span>Submit</span>
          </LoadingButton>
          {isLoading && <Loading />}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
