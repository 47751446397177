import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import { IoMdClose } from "react-icons/io";
import { BsChevronLeft, BsChevronRight, BsPlayCircle } from "react-icons/bs";

const FullScreenSlider = ({ media, initialSlide, onClose }) => {
  const [swiper, setSwiper] = useState(null);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
      <Swiper
        onSwiper={setSwiper}
        initialSlide={initialSlide}
        modules={[Navigation]}
        className="w-full h-full"
      >
        {Array.isArray(media) &&
          media.map((item, index) => (
            <SwiperSlide
              key={index}
              className="flex items-center justify-center"
            >
              {item && item.type === "image" ? (
                <img
                  src={item.src}
                  alt={`Full-screen image ${index + 1}`}
                  className="max-w-full max-h-full mx-auto object-contain"
                />
              ) : item && item.type === "video" ? (
                <div className="w-full h-full">
                  <iframe
                    src={`https://www.youtube.com/embed/${
                      item.src.split("v=")[1]
                    }?autoplay=1&enablejsapi=1`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full"
                  />
                </div>
              ) : null}
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="absolute bottom-4 left-0 right-0 flex justify-center items-center space-x-4">
        <button
          onClick={() => swiper && swiper.slidePrev()}
          className="text-white z-10 bg-black p-2 rounded-full hover:text-white"
        >
          <BsChevronLeft size={32} />
        </button>
        <button
          onClick={onClose}
          className="text-white z-10 mx-4 bg-black p-2 rounded-full hover:text-white"
        >
          <IoMdClose size={20} />
        </button>
        <button
          onClick={() => swiper && swiper.slideNext()}
          className="text-white z-10 bg-black p-2 rounded-full hover:text-white"
        >
          <BsChevronRight size={32} />
        </button>
      </div>
    </div>
  );
};

export default function GalleryImage({ arrayOfImage = [], videoUrl = "" }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [initialFullScreenSlide, setInitialFullScreenSlide] = useState(0);
  const [isDesktop, setIsDesktop] = useState(false);
  const mainSwiperRef = useRef(null);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const openFullScreen = (index) => {
    if (isDesktop) {
      setInitialFullScreenSlide(index);
      setFullScreenOpen(true);
    }
  };

  const media = [
    ...(videoUrl ? [{ type: "video", src: videoUrl }] : []),
    ...(Array.isArray(arrayOfImage)
      ? arrayOfImage.map((pic) => ({ type: "image", src: pic }))
      : []),
  ];

  const handleVideoClick = (index) => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      mainSwiperRef.current.swiper.slideTo(index);
    }
  };

  const onYouTubeIframeAPIReady = (event) => {
    if (window.YT && window.YT.Player) {
      new window.YT.Player(event.target, {
        events: {
          onStateChange: (state) => {
            if (state.data === window.YT.PlayerState.PLAYING) {
              if (
                mainSwiperRef.current &&
                mainSwiperRef.current.swiper &&
                mainSwiperRef.current.swiper.autoplay
              ) {
                mainSwiperRef.current.swiper.autoplay.stop();
              }
            } else if (
              state.data === window.YT.PlayerState.PAUSED ||
              state.data === window.YT.PlayerState.ENDED
            ) {
              if (
                mainSwiperRef.current &&
                mainSwiperRef.current.swiper &&
                mainSwiperRef.current.swiper.autoplay
              ) {
                mainSwiperRef.current.swiper.autoplay.start();
              }
            }
          },
        },
      });
    }
  };

  if (!Array.isArray(media) || media.length === 0) {
    return <div>No media to display</div>;
  }

  return (
    <div className="flex flex-col h-96 md:h-screen w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex-grow overflow-hidden">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs, Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          ref={mainSwiperRef}
          className="h-full"
        >
          {media.map((item, index) => (
            <SwiperSlide
              key={index}
              className="flex items-center justify-center"
            >
              <div
                className={`border-2 hover:border-mediumgray rounded-lg h-full w-full flex items-center justify-center overflow-hidden ${
                  isDesktop ? "cursor-pointer" : ""
                }`}
                onClick={() => openFullScreen(index)}
              >
                {item && item.type === "image" ? (
                  <img
                    src={item.src}
                    alt={`Gallery image ${index + 1}`}
                    className="object-contain max-w-full max-h-full transition-transform duration-300 ease-in-out hover:scale-110"
                  />
                ) : item && item.type === "video" ? (
                  <div className="w-full h-full">
                    <iframe
                      className="w-full h-full"
                      src={`https://www.youtube.com/embed/${
                        item.src.split("v=")[1]
                      }?enablejsapi=1`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      onLoad={(event) => onYouTubeIframeAPIReady(event)}
                    ></iframe>
                  </div>
                ) : null}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* bottom Gallery */}
      <div className="mt-2 sm:mt-4 flex-shrink-0">
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={5}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full"
          breakpoints={{
            640: {
              spaceBetween: 10,
            },
          }}
        >
          {media.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="h-16 sm:h-20 md:h-24 lg:h-32 border-2 rounded-lg hover:border-mediumgray cursor-pointer"
                onClick={() => handleVideoClick(index)}
              >
                {item && item.type === "image" ? (
                  <img
                    src={item.src}
                    alt={`Thumbnail ${index + 1}`}
                    className="object-contain w-full h-full"
                  />
                ) : item && item.type === "video" ? (
                  <div className="w-full h-full flex items-center justify-center bg-black">
                    <BsPlayCircle size={48} className="text-white" />
                  </div>
                ) : null}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {isDesktop && fullScreenOpen && (
        <FullScreenSlider
          media={media}
          initialSlide={initialFullScreenSlide}
          onClose={() => setFullScreenOpen(false)}
        />
      )}
    </div>
  );
}
