import React, { useEffect, useMemo, useState } from "react";
import { formatDateTimeWithAMAndPM } from "../../utils/DateCalculate";
import { getUsers } from "../../utils/functions/api-functions";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Loading from "../../components/Loading";
import { Avatar } from "@mui/material";
import { Helmet } from "react-helmet";

function Users() {
  // TODO: If CSV Export is needed in future then Visit this link https://www.material-react-table.com/docs/examples/export-csv
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getUsers(5000, 1)
      .then((res) => {
        setLoading(false);
        const array = [];
        res.forEach((element) => {
          const obj = { ...element };
          obj.created_at = formatDateTimeWithAMAndPM(element.created_at);
          obj.profile_photo_url = (
            <Avatar src={element.profile_photo_url} alt="logo" />
          );
          array.push(obj);
        });
        setData(array);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at", //access nested data with dot notation
        header: "Date",
        size: 150,
      },
      {
        accessorKey: "profile_photo_url",
        header: "Profile",
        size: 100,
        filter: false,
      },
      {
        accessorKey: "full_name",
        header: "Full Name",
        size: 150,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 100,
      },
      {
        accessorKey: "role",
        header: "Role",
        size: 50,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  return (
    <>
      <Helmet>
        <title>Users | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className="pt-2 pr-2 pl-2 lg:pl-0 pb-5">
        <MaterialReactTable table={table} />
      </div>
    </>
  );
}

export default Users;
