import React from "react";
import { useNavigate } from "react-router-dom";

function Dashboard({ data, title, icon, link, children }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => {
          if (link) {
            navigate(link);
          }
        }}
        className={`flex justify-between gap-5 items-center min-w-[270px] px-3 py-5 bg-white overflow-hidden border-2 border-lightgray ${
          children && "border-b-0 rounded-b-none"
        } rounded cursor-pointer`}
      >
        <div className="relative">
          <div className="text-lightblue text-3xl relative z-10">{icon}</div>
          <div className="absolute top-[-150%] -right-[180%] w-32 h-32 rounded-full bg-lightPurple bg-opacity-85"></div>
        </div>
        <div>
          <h3 className="text-xl font-medium text-mediumgray">{title}</h3>
          <h6 className="text-end font-medium text-2xl">
            {Array.isArray(data) ? data?.length : data}
          </h6>
        </div>
      </div>
      {children && (
        <div className={`rounded-b border-2 border-lightgray`}>{children}</div>
      )}
    </>
  );
}

export default Dashboard;
