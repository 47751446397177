import React from "react";
import ResetPasswordForm from "../../components/ui/forms/ResetPasswordForm";
import { Helmet } from "react-helmet";

const ResetPassword = () => {
  return (
    <>
      <Helmet>
        <title>Reset Password | Inflection Admin</title>
      </Helmet>
      <div>
        <ResetPasswordForm />
      </div>
    </>
  );
};

export default ResetPassword;
